import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import * as jsUtility from "@/utils/jsUtils.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/signup",
    name: "signup",
    component: () =>
      import(/* webpackChunkName: "signup" */ "../views/Signup.vue"),
  },
  {
    path: "/reset-password",
    name: "reset-password",
    component: () =>
      import(
        /* webpackChunkName: "reset-password" */ "../views/ResetPassword.vue"
      ),
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: () =>
      import(
        /* webpackChunkName: "forgot-password" */ "../views/ForgotPassword.vue"
      ),
  },
  {
    path: "/email-confirm",
    name: "email-confirm",
    component: () =>
      import(
        /* webpackChunkName: "emial-confirm" */ "../views/EmailConfirm.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  // base: "/app",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.requireAuth) {
    // need log

    let account = jsUtility.readCookie("HS-STUDIO");
    console.log("account", account);

    if (account) {
      next();
    } else {
      if (window.location.href.indexOf("http://localhost") !== -1) {
        next({
          name: "login",
        });
        // next();
      }
      next({
        name: "login",
      });
    }
  } else {
    next();
  }
});

export default router;
