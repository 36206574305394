<template>
  <div class="home">
    <nav>
      <button class="button" @click="logout">Logout</button>
    </nav>
    <h1>Dashboard</h1>
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "Home",
  components: {
    // HelloWorld,
  },
  methods: {
    logout() {
      this.$router.push({ name: "login" });
    },
  },
};
</script>
